// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-request-demo-js": () => import("./../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-request-demo-loadmate-js": () => import("./../src/pages/request-demo-loadmate.js" /* webpackChunkName: "component---src-pages-request-demo-loadmate-js" */),
  "component---src-pages-request-pricing-js": () => import("./../src/pages/request-pricing.js" /* webpackChunkName: "component---src-pages-request-pricing-js" */),
  "component---src-pages-request-pricing-loadmate-js": () => import("./../src/pages/request-pricing-loadmate.js" /* webpackChunkName: "component---src-pages-request-pricing-loadmate-js" */),
  "component---src-pages-sign-up-carrier-js": () => import("./../src/pages/sign-up-carrier.js" /* webpackChunkName: "component---src-pages-sign-up-carrier-js" */),
  "component---src-pages-sign-up-carrier-not-verified-js": () => import("./../src/pages/sign-up-carrier-not-verified.js" /* webpackChunkName: "component---src-pages-sign-up-carrier-not-verified-js" */),
  "component---src-pages-sign-up-carrier-welcome-js": () => import("./../src/pages/sign-up-carrier-welcome.js" /* webpackChunkName: "component---src-pages-sign-up-carrier-welcome-js" */),
  "component---src-pages-sign-up-js": () => import("./../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-sign-up-loadmate-js": () => import("./../src/pages/sign-up-loadmate.js" /* webpackChunkName: "component---src-pages-sign-up-loadmate-js" */),
  "component---src-pages-sign-up-shipper-js": () => import("./../src/pages/sign-up-shipper.js" /* webpackChunkName: "component---src-pages-sign-up-shipper-js" */),
  "component---src-pages-sign-up-shipper-welcome-js": () => import("./../src/pages/sign-up-shipper-welcome.js" /* webpackChunkName: "component---src-pages-sign-up-shipper-welcome-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-legal-js": () => import("./../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}


import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

export const colors = {
  shipCars: "#3c117f",
  grayDarkest: "#242f35",
  grayMid: "#a3afba",
  grayDark: "#5a6977",
  grayLightest: "#e6ebef",
  grayBackground: "#f3f5f7",
  shipperTMS: "#12d7b1",
  white: "#fff",
  link: "#5a96e5",
  cta: "#05bf6d",
  greenHover: "#54dea1",
  greenActive: "#06874e",
  disabled: "#a2e0c5",
  formTitle: "#3f4a54",
  borderColor: "#cdd5dc",
}

export const boxShadow = {
  card: "0 1px 17px 0 rgba(121, 129, 137, .26)",
  cardLighter: "0 1px 10px 0 rgba(121, 129, 137, .26)",
  cardHover: "0 1px 30px 0 rgba(121, 129, 137, .7)",
  img: "0 2px 16px 0 rgba(121, 129, 137, .3)",
  form: "0 8px 25px 0 rgba(0, 0, 0, .2)",
}

let theme = createMuiTheme({
  breakpoints: {
    values: {
      lg: 1280,
      md: 960,
      sm: 600,
      xl: 1440,
      xs: 0,
    },
  },
  typography: {
    h1: {
      fontSize: "60px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.58,
      letterSpacing: "normal",
    },
    h2: {
      fontSize: "48px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
    },
    h3: {
      fontSize: "36px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
    },
    h4: {
      fontSize: "30px",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
    },
    h5: {
      fontSize: "24px",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
    },
    subtitle1: {
      fontSize: "20px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
    },
    subtitle2: {
      fontSize: "18px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
    },
    body1: {
      fontSize: "16px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
    },
    caption: {
      fontSize: "12px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
    },
    overline: {
      fontSize: "76px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.21,
      letterSpacing: "sormal",
    },
  },
  palette: {
    text: {
      primary: colors.grayDarkest,
      secondary: colors.grayDark,
    },
    primary: {
      main: colors.link,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.shipCars,
      contrastText: colors.white,
    },
    background: {
      default: colors.white,
    },
    action: {
      hoverOpacity: 0.1,
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
        letterSpacing: "-.3px",
        color: "inherit",
      },
      root: {
        borderRadius: 6,
        "&:disabled": {
          backgroundColor: colors.disabled,
          borderColor: colors.disabled,
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
      elevation4: {
        boxShadow: boxShadow.card,
      },
      elevation1: {
        boxShadow: boxShadow.cardLighter,
      },
    },
    MuiCardActionArea: {
      focusHighlight: {
        display: "none",
      },
    },
  },
})

export default responsiveFontSizes(theme)

import React from "react"
import TopLayout from "./TopLayout"
import Cookies from "js-cookie"

export const onRouteUpdate = ({ location }) => {
  if (location.search) {
    const urlSearchParams = new URLSearchParams(location.search)

    if (urlSearchParams.has("utm_campaign")) {
      Cookies.set("source_campaign", urlSearchParams.get("utm_campaign"), {
        expires: 30,
      })
    }
  }
}

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>
}

import { dom, library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fal } from "@fortawesome/pro-light-svg-icons"
import { fas } from "@fortawesome/pro-solid-svg-icons"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/styles"
import React from "react"
import { Helmet } from "react-helmet"
import theme from "../../src/theme"
import faviconImg from "../../src/img/favicon.png"
import { SnackbarProvider } from "notistack"
import mixpanel from "mixpanel-browser"

library.add(fab, fal, fas)

mixpanel.init(
  process.env.GATSBY_ACTIVE_ENV === "production"
    ? "bc4d2a22684f5f88d2ba9c0910025b08"
    : "61e0871e3dff2f6333c7b1ae05673f26"
)

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={faviconImg} />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <style>{dom.css()}</style>
        <script>
          {`function importIntercom(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + intercomSettings.app_id;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}}`}
        </script>
        <script src="/app/configs/app.js" onload="importIntercom()" async />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default Layout
